import React, { useContext } from 'react';

import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import Grid from 'components/_designSystem/_layout/Grid';
import styles from 'components/FeaturedCompanies/FeaturedCompanies.module.scss';
import FeaturedCompanyCard from 'components/FeaturedCompanyCard';
import Link from 'components/Link';
import { useGetSearchByParamsQuery } from 'store/api';
import { PlanTypeEnum } from 'utils/plans';

const MAX_FEATURED_COMPANIES_ITEMS = 6;

const FeaturedCompanies = () => {
  const { t } = useTranslation('home');

  const { domainLocation, domainCountryMeta, domainLocationDisplayName, language } =
    useContext(InternationalizationContext);

  const { data: { data: featuredCompanies } = { data: [] }, isFetching } = useGetSearchByParamsQuery(
    {
      location: domainLocation?.id,
      page: 1,
      size: MAX_FEATURED_COMPANIES_ITEMS,
      sort: 'random',
      plans: [PlanTypeEnum.GOLD],
      language,
    },
    { skip: domainCountryMeta && !domainLocation?.id },
  );

  const renderCompanies = () =>
    featuredCompanies.map((company) => <FeaturedCompanyCard key={company.id} company={company} />);

  const renderCompaniesLoader = () => [1, 2, 3, 4, 5, 6].map((number) => <FeaturedCompanyCard key={number} />);
  const location = domainLocation?.id ? `?location=${domainLocation.id}` : '';

  return (
    <>
      <div className={styles.title__company}>{t('section_4.title')}</div>
      <Grid className="gap-0 sm:gap-6">{isFetching ? renderCompaniesLoader() : renderCompanies()}</Grid>
      <div className={styles.browse__button}>
        <Link
          href={`/companies${location}`}
          className="flex items-center text-lg font-semibold text-blue-600 hover:text-gray-300"
          data-testid="featured_companies--explore_link_button"
        >
          {t('section_4.button')} {domainLocationDisplayName}
          <FontAwesomeIcon icon={faLongArrowAltRight} className="mt-0.5 ml-1" />
        </Link>
      </div>
    </>
  );
};

export { FeaturedCompanies };
