import { DunsguideCompanyPlan } from 'store/models/Company';

export const MAX_INFOGRAPHIC_FREE = 0;
export const MAX_INFOGRAPHIC_BASIC = 3;
export const MAX_INFOGRAPHIC_SILVER = 10;
export const MAX_INFOGRAPHIC_GOLD = -1;

export enum NewPlanTypeEnum {
  ACCESS = 'duns_access',
  BASIC = 'duns_basic',
  SILVER = 'duns_silver',
  GOLD = 'duns_gold',
}

export enum PlanTypeEnum {
  FREE = 'free',
  ACCESS = 'access',
  BASIC = 'basic',
  SILVER = 'silver',
  GOLD = 'gold',
}

export const getInfographicLimitByPlanType = (plans: DunsguideCompanyPlan[]) => {
  if (plans.find((plan) => plan.type === PlanTypeEnum.GOLD)) {
    return { type: PlanTypeEnum.GOLD, limit: MAX_INFOGRAPHIC_GOLD };
  }
  if (plans.find((plan) => plan.type === PlanTypeEnum.SILVER)) {
    return { type: PlanTypeEnum.SILVER, limit: MAX_INFOGRAPHIC_SILVER };
  }
  if (plans.find((plan) => plan.type === PlanTypeEnum.BASIC)) {
    return { type: PlanTypeEnum.BASIC, limit: MAX_INFOGRAPHIC_BASIC };
  }
  return { type: PlanTypeEnum.FREE, limit: MAX_INFOGRAPHIC_FREE };
};
