import React, { useContext } from 'react';

import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Truncate from 'react-truncate';
import classnames from 'classnames';

import { InternationalizationContext } from 'components/_context/InternationalizationContext';
import Column from 'components/_designSystem/_layout/Column';
import CompanyBannerCard from 'components/CompanyBannerCard';
import CompanyFeatures from 'components/CompanyFeatures';
import DUNSRegisteredSeal from 'components/DUNSRegisteredSeal';
import Link from 'components/Link';
import { getTextFromHtml } from 'utils/formatString';
import { companyPath } from 'utils/routes';

import styles from './FeaturedCompanyCard.module.scss';

const propTypes = {
  company: PropTypes.object,
};

const FeaturedCompanyCard = ({ company }) => {
  const { getTranslated, getSlug } = useContext(InternationalizationContext);

  const renderDescription = () => {
    if (!company) {
      return (
        <p className={styles.result_card__description}>
          <Skeleton count={2} />
        </p>
      );
    }

    if (isEmpty(company?.description)) return null;

    return (
      <p className={styles.result_card__description}>
        <Truncate lines={2}>{getTextFromHtml(getTranslated(company, 'description'))?.substring(0, 400)}</Truncate>
      </p>
    );
  };

  return (
    <Column col={12} sm={6} lg={4} key={`${company?.id}`}>
      <Link
        onClick={() =>
          window?.analytics?.track('company_card--clicked', {
            source: 'Featured companies',
            company: company?.id,
          })
        }
        data-testid="company_profile--search_link_button"
        href={company ? companyPath(company.id, getSlug(company)) : '#'}
        className={styles.result_card__link}
      >
        <div className={classnames('shadow rounded-xl', styles.result_card__container)}>
          <div>
            <CompanyBannerCard company={company}>
              <div className={styles.result_card__data}>
                <div>
                  <div className={styles.result_card__title_container}>
                    <h2 className={styles.result_card__title}>
                      {company ? getTranslated(company, 'legalName') : <Skeleton width="50%" />}
                    </h2>
                    {company?.tradeName && (
                      <p className={styles.result_card__subtitle}>{getTranslated(company, 'tradeName')}</p>
                    )}
                  </div>
                  <div className={styles.attributes_container_item}>
                    {company ? (
                      <CompanyFeatures
                        className={styles.attributes_container_item}
                        icon={faMapMarkerAlt}
                        data={company?.shortLocation}
                      />
                    ) : (
                      <Skeleton width={100} />
                    )}
                  </div>
                  {renderDescription()}
                </div>
              </div>
            </CompanyBannerCard>
          </div>
          <div className={styles.result_card__seal}>
            <DUNSRegisteredSeal badges={company?.badges} />
          </div>
        </div>
      </Link>
    </Column>
  );
};

FeaturedCompanyCard.propTypes = propTypes;

export default FeaturedCompanyCard;
